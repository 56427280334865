/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Bursa Yenişehir Şemaki Evi, İran'ın Şemaki kasabasından Anadolu'ya gelerek Yenişehir'e yerleşen Şemaki ailesi tarafından 18. yüzyılda yaptırılmıştır. Konak olarak adlandırılabilecek ev iki katlıdır. Zemin katta taşlık ve sağ tarafında mutfak ve kiler, sol tarafında iki oda yer alır. Mutfak duvarına bitişik ahşap merdivenle üst kata çıkılır. Burada eyvanlı bir sofa, sofaya açılan bir \"başoda\" ile biri küçük diğeri büyük iki oda daha yer alır. Bu odalarda görülen zengin kalem işi süslemeler 19. yüzyılda yapılmıştır.");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
